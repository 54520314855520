.fb_hidden {
  position: absolute;
  top: -10000px;
  z-index: 10001;
}
.fb_reposition {
  overflow: hidden;
  position: relative;
}
.fb_invisible {
  display: none;
}
.fb_reset {
  background: none;
  border: 0;
  border-spacing: 0;
  color: #000;
  cursor: auto;
  direction: ltr;
  font-family: 'lucida grande', tahoma, verdana, arial, sans-serif;
  font-size: 11px;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1;
  margin: 0;
  overflow: visible;
  padding: 0;
  text-align: left;
  text-decoration: none;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  word-spacing: normal;
}
.fb_reset > div {
  overflow: hidden;
}
@keyframes fb_transform {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
.fb_animate {
  animation: fb_transform 0.3s forwards;
}
.fb_hidden {
  position: absolute;
  top: -10000px;
  z-index: 10001;
}
.fb_reposition {
  overflow: hidden;
  position: relative;
}
.fb_invisible {
  display: none;
}
.fb_reset {
  background: none;
  border: 0;
  border-spacing: 0;
  color: #000;
  cursor: auto;
  direction: ltr;
  font-family: 'lucida grande', tahoma, verdana, arial, sans-serif;
  font-size: 11px;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1;
  margin: 0;
  overflow: visible;
  padding: 0;
  text-align: left;
  text-decoration: none;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  word-spacing: normal;
}
.fb_reset > div {
  overflow: hidden;
}
@keyframes fb_transform {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
.fb_animate {
  animation: fb_transform 0.3s forwards;
}
.fb_dialog {
  background: rgba(82, 82, 82, 0.7);
  position: absolute;
  top: -10000px;
  z-index: 10001;
}
.fb_dialog_advanced {
  border-radius: 8px;
  padding: 10px;
}
.fb_dialog_content {
  background: #fff;
  color: #373737;
}
.fb_dialog_close_icon {
  background: url(https://connect.facebook.net/rsrc.php/v3/yq/r/IE9JII6Z1Ys.png)
    no-repeat scroll 0 0 transparent;
  cursor: pointer;
  display: block;
  height: 15px;
  position: absolute;
  right: 18px;
  top: 17px;
  width: 15px;
}
.fb_dialog_mobile .fb_dialog_close_icon {
  left: 5px;
  right: auto;
  top: 5px;
}
.fb_dialog_padding {
  background-color: transparent;
  position: absolute;
  width: 1px;
  z-index: -1;
}
.fb_dialog_close_icon:hover {
  background: url(https://connect.facebook.net/rsrc.php/v3/yq/r/IE9JII6Z1Ys.png)
    no-repeat scroll 0 -15px transparent;
}
.fb_dialog_close_icon:active {
  background: url(https://connect.facebook.net/rsrc.php/v3/yq/r/IE9JII6Z1Ys.png)
    no-repeat scroll 0 -30px transparent;
}
.fb_dialog_iframe {
  line-height: 0;
}
.fb_dialog_content .dialog_title {
  background: #6d84b4;
  border: 1px solid #365899;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  margin: 0;
}
.fb_dialog_content .dialog_title > span {
  background: url(https://connect.facebook.net/rsrc.php/v3/yd/r/Cou7n-nqK52.gif)
    no-repeat 5px 50%;
  float: left;
  padding: 5px 0 7px 26px;
}
body.fb_hidden {
  height: 100%;
  left: 0;
  margin: 0;
  overflow: visible;
  position: absolute;
  top: -10000px;
  transform: none;
  width: 100%;
}
.fb_dialog.fb_dialog_mobile.loading {
  background: url(https://connect.facebook.net/rsrc.php/v3/ya/r/3rhSv5V8j3o.gif)
    white no-repeat 50% 50%;
  min-height: 100%;
  min-width: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 10001;
}
.fb_dialog.fb_dialog_mobile.loading.centered {
  background: none;
  height: auto;
  min-height: initial;
  min-width: initial;
  width: auto;
}
.fb_dialog.fb_dialog_mobile.loading.centered #fb_dialog_loader_spinner {
  width: 100%;
}
.fb_dialog.fb_dialog_mobile.loading.centered .fb_dialog_content {
  background: none;
}
.loading.centered #fb_dialog_loader_close {
  clear: both;
  color: #fff;
  display: block;
  font-size: 18px;
  padding-top: 20px;
}
#fb-root #fb_dialog_ipad_overlay {
  background: rgba(0, 0, 0, 0.4);
  bottom: 0;
  left: 0;
  min-height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 10000;
}
#fb-root #fb_dialog_ipad_overlay.hidden {
  display: none;
}
.fb_dialog.fb_dialog_mobile.loading iframe {
  visibility: hidden;
}
.fb_dialog_mobile .fb_dialog_iframe {
  position: sticky;
  top: 0;
}
.fb_dialog_content .dialog_header {
  background: linear-gradient(from(#738aba), to(#2c4987));
  border-bottom: 1px solid;
  border-color: #043b87;
  box-shadow: white 0 1px 1px -1px inset;
  color: #fff;
  font: bold 14px Helvetica, sans-serif;
  text-overflow: ellipsis;
  text-shadow: rgba(0, 30, 84, 0.296875) 0 -1px 0;
  vertical-align: middle;
  white-space: nowrap;
}
.fb_dialog_content .dialog_header table {
  height: 43px;
  width: 100%;
}
.fb_dialog_content .dialog_header td.header_left {
  font-size: 12px;
  padding-left: 5px;
  vertical-align: middle;
  width: 60px;
}
.fb_dialog_content .dialog_header td.header_right {
  font-size: 12px;
  padding-right: 5px;
  vertical-align: middle;
  width: 60px;
}
.fb_dialog_content .touchable_button {
  background: linear-gradient(from(#4267b2), to(#2a4887));
  background-clip: padding-box;
  border: 1px solid #29487d;
  border-radius: 3px;
  display: inline-block;
  line-height: 18px;
  margin-top: 3px;
  max-width: 85px;
  padding: 4px 12px;
  position: relative;
}
.fb_dialog_content .dialog_header .touchable_button input {
  background: none;
  border: none;
  color: #fff;
  font: bold 12px Helvetica, sans-serif;
  margin: 2px -12px;
  padding: 2px 6px 3px 6px;
  text-shadow: rgba(0, 30, 84, 0.296875) 0 -1px 0;
}
.fb_dialog_content .dialog_header .header_center {
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  line-height: 18px;
  text-align: center;
  vertical-align: middle;
}
.fb_dialog_content .dialog_content {
  background: url(https://connect.facebook.net/rsrc.php/v3/y9/r/jKEcVPZFk-2.gif)
    no-repeat 50% 50%;
  border: 1px solid #4a4a4a;
  border-bottom: 0;
  border-top: 0;
  height: 150px;
}
.fb_dialog_content .dialog_footer {
  background: #f5f6f7;
  border: 1px solid #4a4a4a;
  border-top-color: #ccc;
  height: 40px;
}
#fb_dialog_loader_close {
  float: left;
}
.fb_dialog.fb_dialog_mobile .fb_dialog_close_icon {
  visibility: hidden;
}
#fb_dialog_loader_spinner {
  animation: rotateSpinner 1.2s linear infinite;
  background-color: transparent;
  background-image: url(https://connect.facebook.net/rsrc.php/v3/yD/r/t-wz8gw1xG1.png);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  height: 24px;
  width: 24px;
}
@keyframes rotateSpinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.fb_iframe_widget {
  display: inline-block;
  position: relative;
}
.fb_iframe_widget span {
  display: inline-block;
  position: relative;
  text-align: justify;
}
.fb_iframe_widget iframe {
  position: absolute;
}
.fb_iframe_widget_fluid_desktop,
.fb_iframe_widget_fluid_desktop span,
.fb_iframe_widget_fluid_desktop iframe {
  max-width: 100%;
}
.fb_iframe_widget_fluid_desktop iframe {
  min-width: 220px;
  position: relative;
}
.fb_iframe_widget_lift {
  z-index: 1;
}
.fb_iframe_widget_fluid {
  display: inline;
}
.fb_iframe_widget_fluid span {
  width: 100%;
}
.fb_mpn_mobile_landing_page_slide_out {
  animation-duration: 200ms;
  animation-name: fb_mpn_landing_page_slide_out;
  transition-timing-function: ease-in;
}
.fb_mpn_mobile_landing_page_slide_out_from_left {
  animation-duration: 200ms;
  animation-name: fb_mpn_landing_page_slide_out_from_left;
  transition-timing-function: ease-in;
}
.fb_mpn_mobile_landing_page_slide_up {
  animation-duration: 500ms;
  animation-name: fb_mpn_landing_page_slide_up;
  transition-timing-function: ease-in;
}
.fb_mpn_mobile_bounce_in {
  animation-duration: 300ms;
  animation-name: fb_mpn_bounce_in;
  transition-timing-function: ease-in;
}
.fb_mpn_mobile_bounce_out {
  animation-duration: 300ms;
  animation-name: fb_mpn_bounce_out;
  transition-timing-function: ease-in;
}
.fb_mpn_mobile_bounce_out_v2 {
  animation-duration: 300ms;
  animation-name: fb_mpn_fade_out;
  transition-timing-function: ease-in;
}
.fb_customer_chat_bounce_in_v2 {
  animation-duration: 300ms;
  animation-name: fb_bounce_in_v2;
  transition-timing-function: ease-in;
}
.fb_customer_chat_bounce_in_from_left {
  animation-duration: 300ms;
  animation-name: fb_bounce_in_from_left;
  transition-timing-function: ease-in;
}
.fb_customer_chat_bounce_out_v2 {
  animation-duration: 300ms;
  animation-name: fb_bounce_out_v2;
  transition-timing-function: ease-in;
}
.fb_customer_chat_bounce_out_from_left {
  animation-duration: 300ms;
  animation-name: fb_bounce_out_from_left;
  transition-timing-function: ease-in;
}
.fb_invisible_flow {
  display: inherit;
  height: 0;
  overflow-x: hidden;
  width: 0;
}
@keyframes fb_mpn_landing_page_slide_out {
  0% {
    margin: 0 12px;
    width: 100% - 24px;
  }
  60% {
    border-radius: 18px;
  }
  100% {
    border-radius: 50%;
    margin: 0 24px;
    width: 60px;
  }
}
@keyframes fb_mpn_landing_page_slide_out_from_left {
  0% {
    left: 12px;
    width: 100% - 24px;
  }
  60% {
    border-radius: 18px;
  }
  100% {
    border-radius: 50%;
    left: 12px;
    width: 60px;
  }
}
@keyframes fb_mpn_landing_page_slide_up {
  0% {
    bottom: 0;
    opacity: 0;
  }
  100% {
    bottom: 24px;
    opacity: 1;
  }
}
@keyframes fb_mpn_bounce_in {
  0% {
    opacity: 0.5;
    top: 100%;
  }
  100% {
    opacity: 1;
    top: 0;
  }
}
@keyframes fb_mpn_fade_out {
  0% {
    bottom: 30px;
    opacity: 1;
  }
  100% {
    bottom: 0;
    opacity: 0;
  }
}
@keyframes fb_mpn_bounce_out {
  0% {
    opacity: 1;
    top: 0;
  }
  100% {
    opacity: 0.5;
    top: 100%;
  }
}
@keyframes fb_bounce_in_v2 {
  0% {
    opacity: 0;
    transform: scale(0, 0);
    transform-origin: bottom right;
  }
  50% {
    transform: scale(1.03, 1.03);
    transform-origin: bottom right;
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
    transform-origin: bottom right;
  }
}
@keyframes fb_bounce_in_from_left {
  0% {
    opacity: 0;
    transform: scale(0, 0);
    transform-origin: bottom left;
  }
  50% {
    transform: scale(1.03, 1.03);
    transform-origin: bottom left;
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
    transform-origin: bottom left;
  }
}
@keyframes fb_bounce_out_v2 {
  0% {
    opacity: 1;
    transform: scale(1, 1);
    transform-origin: bottom right;
  }
  100% {
    opacity: 0;
    transform: scale(0, 0);
    transform-origin: bottom right;
  }
}
@keyframes fb_bounce_out_from_left {
  0% {
    opacity: 1;
    transform: scale(1, 1);
    transform-origin: bottom left;
  }
  100% {
    opacity: 0;
    transform: scale(0, 0);
    transform-origin: bottom left;
  }
}
@keyframes slideInFromBottom {
  0% {
    opacity: 0.1;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes slideInFromBottomDelay {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  97% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.pos_translation {
  padding-bottom: 3px;
}

strong {
  font-weight: bold;
}

.rtl {
  direction: rtl;
}

main {
  color: #333;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: initial;

  background: #fcf7d9;
  text-align: left;
  border-style: solid;
  border-width: 1px;
  border-color: #ccc;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 5px;
  border-radius: 5px;
  padding: 6px 8px 3px 8px;
  position: fixed;
  z-index: 2147483647;
  top: -1500px;
  left: 0;
  box-sizing: content-box;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;

  /* To fix the following issue:
      1. Have translate on hover enabled (opposed to translate on click).
      2. Wisit a website that has a dropdown menu that opens on hover.
      3. Open the menu.
      4. Hover over a word within that menu.
      5. Incidentally move the cursor over the TransOver popup.
      The hover menu gets closed.
      */
  pointer-events: none;
}

.pos_translation {
  font-size: 1em;
  line-height: 1.2em;
}

.red {
  color: red;
}

.from_lang {
  color: grey;
  font-size: 0.8em;
  line-height: 1.2em;
  margin-top: 2px;
  margin-bottom: 3px;
}

#tat_input_container {
  display: flex;
  margin-bottom: 10px;
}

#tat_input_container label {
  line-height: 26px;
}

#tat_input {
  margin-left: 5px;
  flex-grow: 2;
  font-size: 14px;
  line-height: 20px;
  border-radius: 2px;
  border: 1px #ccc solid;
  padding-left: 5px;
}

#tat_submit_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#tat_submit_container select {
  margin-left: 5px;
}

#tat_submit {
  width: 45px;
  margin-left: 5px;
}

#tat_to_lang {
  width: 150px;
}

#swap_languages {
  margin-left: 5px;
  margin-right: 5px;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAEKElEQVR4nL1Vb0xbVRQ/97Xre23pn9FSNmBQGEqGMJQPIyU6MkMczf6gbAyNk5lo9gkTdTPGLPswzRITjcYPftREiYQ/g5EVZZEwNt3ELBmiI/wLqB1rFdaWDaSjfe+e44cHpUAL0URPcnPuu/e887vn3PM7F+A/Fs1mBi+fbNBXPHNgv6ShCa/X+48BhI02T7522lbmrrtsTct8RRCETQ+TSLTJNhreOuvcXXG4y2C1F874vL25BcWP5hWWKgQEAABKNBJZmAvNzIaCi1d6vqVkfliixdfPnH+8eO9BzxaDKQuRgCMRR2KcYhoQgaKRh/PBO+O90yM/fhTy/faD51InrvW1LuzT5z6sLKo4/LVGZ3AoSMARQEEChYgpSKAgMa5qQKaRREvaLmteST0jlHIc5usjw8M8KUBN3Yu2soP1fYLOkLrkDJadxb5pWQMgESASIDHBsC3vSR4Ja3fYUq6OjY1SQoBUq3kxHPT/lJpdUAVaMYUjMQWJKbR8clA1qWniBAyJGKpgTHLklC3cHb469POANyHA1NQUDN66+atNxCuWjJ2VgmhUI+EICkfkiKQOIiRgSKDexxIAAtMQoikjRbg4Pj6OAEmq6KsvPhuok6P7H3n6+ZaUdGep/5fv+vyDfe8AQCy/Wslo3+567n3Rnl1CKgAhEOgceeU6UbQCQCApAABAS1PjZC2XD2S7qr9EpPCD+7MDPT09SrzNcYNFdpQfuUgEAhIBEQAJGpEBmDcFAABoa2mePopYK5pt+2RZXleCi0HftTueTx4jIi1jasUTESHiH8s2MR68cea9Ap1kLCHVCJAAwnOh6ZnfR79vb29f5fzZupcMzpLyd32D1862tTY/3OiQsQiMVvuhQlfVB5xWiPUgOO0LTE3sAQD/qr+4rE0v2vsm0xmdxwTh1dbmpvubAoT+nLotq45haZBoScsorjrRkbn7qTZgQjSWZ0JJQYKtO5+oYaIx7ZigeaG1qdGfCCCWopqjtcZ99W+P64yWDI5EnFTGokou4kgMCUite4L4eTjgG/Jfv1Db3Pj56FqAGA9Ghofl/DynvDV7l3ulJQAotBRRHKEwvvaJQCOZ0vQZ+dW5Nn3/0OCtuwkBAAC228yDW/TGfL0ts4gvMZivtIV49sJaMNLqTJGQb36bReqdnJxM3CrGRkd5ulFzWYkuSnr7jlICjSbOGSzPOREgrKwpclS+13/h47Bv7LzH41lVVQnbtdvt1phT7SXWnKLjkj1rj6DTpxKBQKS+BgRMEB25+UjElMW/Fu7daD0XCfk/7ejoCCe95ETicrmYyWQSRVHUQdzrJ6WYTVmHTnmj88HZwI2WU5G5QFNnZ2c0kY8NAZKJu/qI2Vl54nawv60hPDf7TVdXF09m+6/eWafTyWlm4tLcbOBmd3f3uhbyv8rfG8mfJ968lFgAAAAASUVORK5CYII=);
  cursor: pointer;
}

#swap_languages.disabled {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAEIklEQVR4nL1VX0hbVxj/7k0w6TS3QVck+qC2/tfhiMyRUR8GlU4XNzJYsFBUluXBh/Uho0PBPWww6TbEpzGz+pKBC2OS2tkHRbQd3ZiIdkoVghptdO2MRmP+3Xtzb+759nBNFl0S8WH7weF+nO+7v985h/P7DsB/DMVZBTab7YLJZLouCMKG1+s9twCdLdnf31/Q09MzWVZWZqFp+szFpIMyU2JgYKC0s7Pzga6oqHZ9fX2msbGx0mAwxBN5juNi+/v7e36/n5+cnMRzqQ4NDb3q9/t3JEnCuCRhPB4noiiiIIgkJggYi8WQ53lycHgYnJmddfX29l41mUxZTyMJu91+LRKJBCSZGEVRREEUiSATE57nkeM4ZFmWRKNRjEQieHQUFO+OjAyYzWbVab4T59rd3V1gs9ke5ubm5iMAACJgckBKfHIAIF1TW3s1HAopc3JyHrndbkwrkJeXx29sbPyh1+vfuqBW5yEChYDUMVHql0LyT0wQAZFQlZVVry8sLDxaXFzwphXY3t6Gubm5zXA4PFtXV3eNYZh8RASJEJDiEiESQXlIeCxMISIQJICIFEVRCkmSNKIo3FtbWyMAGW6R3W5/wvP89Y9u3fqxprpG/2Bi4qHL5eoDAClRwzDMyxaL5U55RWUDIgEkiAQRqqqr31CpVFoA8GcUAABwOBweQRDetlgs30uEsIFA4Mn09HQ8tSY/v0C0Wq33CCItHx8BhUKhAgDmTAEAAKfT6ZMk6X2dTvemKIrkdP7Zs61f+vp66xBRSVEUAAAgIhJC/krUUIlgcHCwimGYBvlWyNjb2/MtLS09HhsbO0H+gcXyUktLy+c/37//qdPp5LItMrkDnU7X3tHR8fWxACIitbOz83xlZaUJAF6k/hTjeeU77e02RqMppWn6w9HR0aMzBTwez1OUAQmN4uLiotu3P3EZjcafaJoWjueBEKImBKG5ufk9jUZzSalU3nA4HC8yiQAAgNlszt3d3X0utwSByO0ghhzHI8txJMqyGIlESTgcwVAojMFgiBwdBfEwEMCFxcWnVqu1Oh1v0gerq6tiSUmJqG9sbEXZtgljATltNJKcpxAJXGQuXqqvf+VdmqZ/n5+f/zOtAACAWq1eYhim/HJZWT1mEiAJc8nuRSLHOSqVZmtrM6xUKmc8Hk/6VuF2uyVBECZZllWXV1ToFbRCkeJUOBZIkCdjnuPEkbvfDS0vL38xMTFx4lZRkAatra2KwsLChteamm5euXylSW5+CTMhUEDRVdVV5YQgFQoGo8P24c+2vd5vXC4Xe5orrUACBoOB0mg0KpVKlQMpr59Wq9Xc+fIrr8/nC9iHv/3Y5/P9MD4+LmTjOhfMZjPz+NffvF1dXe1GozHrU5p1B5nQ1tam1Gq1pQcHB5tTU1P/aiH/K/4G7RvFhRjTPe0AAAAASUVORK5CYII=);
  cursor: auto;
}

main {
  color: #333;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 19px;
  letter-spacing: initial;

  background: #fcf7d9;
  text-align: left;
  border-style: solid;
  border-width: 1px;
  border-color: #ccc;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 5px;
  border-radius: 5px;
  padding: 10px 12px 12px 12px;
  position: fixed;
  z-index: 2147483647;
  top: 15px;
  right: 15px;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

#disable_on_this_page_container {
  margin-bottom: 8px;
  color: blue;
}

#disable_on_this_page {
  position: relative;
  vertical-align: middle;
  bottom: 1px;
  margin-right: 5px;
}

#top_row_container {
  display: flex;
  justify-content: space-between;
}

#tat_close {
  width: 12px;
  height: 12px;
  background: -webkit-linear-gradient(
      -45deg,
      transparent 0%,
      transparent 46%,
      darkgrey 46%,
      darkgrey 56%,
      transparent 56%,
      transparent 100%
    ),
    -webkit-linear-gradient(45deg, transparent 0%, transparent 46%, darkgrey 46%, darkgrey
          56%, transparent 56%, transparent 100%);
}
